export const markers = {
    type: "FeatureCollection",
    name: "lamp-post",
    crs: {
        type: "name",
        properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84"
        }
    },
    features: [
        {
            type: "Feature",
            properties: {
                fid: 1,
                lamp: "DIOD",
                headband: 1,
                repair: "не потрібен",
                photo: "5.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98836283485168,
                    46.97815219481731
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2,
                lamp: "DIOD",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98815484555748,
                    46.9779169683557
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.988083218686366,
                    46.97769561714638
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 4,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987777751147846,
                    46.97727447257005
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 5,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987693484240662,
                    46.97712642468277
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 6,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "6.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987392997733313,
                    46.97674635214422
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 7,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "7.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987051716759233,
                    46.97637550875308
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 8,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "8.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986862116218077,
                    46.97598597668513
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 9,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986630800672025,
                    46.97567462268469
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 10,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "10.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98644362424737,
                    46.97540809237497
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 11,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986211688242918,
                    46.97506937485618
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 12,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986036718976397,
                    46.97484171105596
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 13,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985863784236233,
                    46.9745529653298
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 14,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98572543644411,
                    46.97436278099507
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 15,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "15.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985595226757397,
                    46.97417398419819
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 16,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985414153911815,
                    46.97391994039758
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 17,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98528190969877,
                    46.97372558913425
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 18,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "18.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98514559643299,
                    46.973535401857056
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 19,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "19.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985009283167212,
                    46.97332994330857
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 20,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "20.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98479769242631,
                    46.97304396590683
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 21,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984586101685398,
                    46.97275798697584
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 22,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984450857355167,
                    46.97256705303375
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 23,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98430339026761,
                    46.97231693008692
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 24,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: "24.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984130643107886,
                    46.9720984309232
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 25,
                lamp: "DNAT",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983970535984245,
                    46.97184830578469
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 26,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983785148788446,
                    46.971612554583245
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 27,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983713521917338,
                    46.97148892853785
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 28,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983587121556567,
                    46.971287676224826
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 29,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983458614523116,
                    46.971084985629844
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 30,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983287974036077,
                    46.97085066859466
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 31,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983062560059366,
                    46.97054591116028
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 32,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982942479716638,
                    46.97036046828673
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 33,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98275317484884,
                    46.97011671238752
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 34,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982607038291388,
                    46.96989782923816
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 35,
                lamp: "LED",
                headband: 0,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98248803603034,
                    46.969714523180485
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 36,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982363144729774,
                    46.96949937241058
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 37,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982179864206657,
                    46.96926361085512
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 38,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981943916866552,
                    46.96896171954118
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 39,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981800663124346,
                    46.96872883079189
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 40,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981615275928544,
                    46.96847293951547
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 41,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981417248696673,
                    46.968182544561536
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 42,
                lamp: "LED",
                headband: 0,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981323480161493,
                    46.96803947550247
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 43,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981095959512107,
                    46.96807397804805
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 44,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981269904300802,
                    46.96792492472528
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 45,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: "45.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981092900507935,
                    46.96778261595473
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 46,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98061948264575,
                    46.96714312705045
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 47,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980577687953595,
                    46.966914955078835
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 48,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "48.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98045081120956,
                    46.96671835969772
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 49,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980314978460054,
                    46.96649629772467
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 50,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98017466770783,
                    46.966272197551234
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 51,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980079136982898,
                    46.96610208452002
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 52,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979913450881856,
                    46.96587798269507
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 53,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979797022810857,
                    46.96568443945433
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 54,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979669473929953,
                    46.965485867758815
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 55,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "112.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979536111501655,
                    46.965297029185514
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 56,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979453883849168,
                    46.96516742713541
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 57,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97930363943786,
                    46.964917872672075
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 58,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97914346136746,
                    46.964707657856486
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 59,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979041314022528,
                    46.964491258792435
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 60,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978861373382575,
                    46.964236768614555
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 61,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97874342645684,
                    46.964040848450516
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 62,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978573803554188,
                    46.96376724531802
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 63,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978412677731814,
                    46.96351640767935
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 64,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "64.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978284983800094,
                    46.96329310532221
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 65,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97813027271525,
                    46.96307028132027
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 66,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978046486277268,
                    46.962946988628616
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 67,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97790482177937,
                    46.962734202225235
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 68,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977763231199795,
                    46.96250320328899
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 69,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97764965567098,
                    46.96232847797299
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 70,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97751453294611,
                    46.96211679881639
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 71,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9774006617439,
                    46.96193849027586
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 72,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977289008091887,
                    46.96175127713859
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 73,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9771070396193,
                    46.96140590728924
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 74,
                lamp: null,
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976903597869057,
                    46.96105839097058
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 75,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "75.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976743786418776,
                    46.96077385846255
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 76,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "76.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97658397496849,
                    46.96050355103056
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 77,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "77.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97628172287773,
                    46.96003406646594
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 78,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "78.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976035057378375,
                    46.959640455033096
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 79,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "79.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97591519879066,
                    46.95944720498154
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 80,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975694589506038,
                    46.95907137311116
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 81,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975597128175224,
                    46.95890750870565
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 82,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97547207682415,
                    46.9586711455147
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 83,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975401355452714,
                    46.958538105605065
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 84,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975302341836766,
                    46.958446813919004
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 85,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97547952409686,
                    46.958378048643944
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 86,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975535110688245,
                    46.958491866982314
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 87,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97561935911585,
                    46.95862465473774
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 88,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975771353701717,
                    46.95886118211082
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 89,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.975872104398636,
                    46.95902242322948
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 90,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976092713683265,
                    46.95939351307565
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 91,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976228205999817,
                    46.95959506239281
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 92,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976466186094257,
                    46.95997444728312
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 93,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976782334832862,
                    46.96044630348683
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 94,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.976938672121182,
                    46.96073083787981
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 95,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9770863240046,
                    46.96101062855734
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 96,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9772826141555,
                    46.961365107018956
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 97,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977478904306384,
                    46.96170772777954
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 98,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977593551651154,
                    46.961880815634565
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 99,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97767780007879,
                    46.9620758351839
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 100,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977851508176926,
                    46.96226789021551
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 101,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.977955733035802,
                    46.96245757352061
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 102,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978087751190387,
                    46.96268045053875
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 103,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978233665992825,
                    46.96288910053437
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 104,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978320520041883,
                    46.96301713535238
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 105,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978454275277446,
                    46.96324475205005
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 106,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978608875484788,
                    46.963472367779275
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 107,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97876868693507,
                    46.96370590999487
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 108,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.978938920871244,
                    46.963999910928344
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 109,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979070939025828,
                    46.96418128973062
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 110,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979206431342377,
                    46.964443280248226
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 111,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979348871982843,
                    46.96464955242265
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 112,
                lamp: null,
                headband: 0,
                repair: "потрібен",
                photo: "112.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.97948089013743,
                    46.96487953310467
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 113,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979649386992616,
                    46.965122552854055
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 114,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979720607312853,
                    46.965250582326796
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 115,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979840465900573,
                    46.96544381141375
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 116,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.979962061569264,
                    46.96565007973023
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 117,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980080183076,
                    46.96581722761018
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 118,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98023478328334,
                    46.96606498485439
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 119,
                lamp: null,
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980347693547127,
                    46.966228575133066
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 120,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98046407797288,
                    46.96646684703524
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 121,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980613466937278,
                    46.966681409382254
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 122,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.980748959253823,
                    46.96686277909275
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 123,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98113111706972,
                    46.96709512109137
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 124,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98143510624145,
                    46.96787156279576
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 125,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "125.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.981612288501537,
                    46.968122866704135
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 126,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98180336740949,
                    46.96840736026985
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 127,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98199444631744,
                    46.968677627755724
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 128,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98216641733459,
                    46.968923001000945
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 129,
                lamp: "DNAT",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982385289538243,
                    46.96920749031091
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 130,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982545100988535,
                    46.969446934307385
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 131,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "131.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982691015790977,
                    46.969666547115416
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 132,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982823292659738,
                    46.969843726911826
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 133,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.982963996219226,
                    46.970060646519336
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 134,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983137704317365,
                    46.97030719876454
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 135,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983241929176245,
                    46.970477888114665
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 136,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98345385305597,
                    46.97078844650727
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 137,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983637983639994,
                    46.97104210736783
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 138,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983770001794575,
                    46.97123413020987
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 139,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983902019949163,
                    46.97143563492004
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 140,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: "140.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.983995822322154,
                    46.97153283103824
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 141,
                lamp: "DRL",
                headband: 1,
                repair: "потрібен",
                photo: "141.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98414521128655,
                    46.97178885899192
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 142,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "142.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984315445222723,
                    46.97204251510772
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 143,
                lamp: "DRL",
                headband: 1,
                repair: "потрібен",
                photo: "143.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984492627482812,
                    46.97226772280043
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 144,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "144.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98465243893311,
                    46.97250952368844
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 145,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "145.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.984791996758332,
                    46.97270368487418
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 146,
                lamp: "DNAT",
                headband: 0,
                repair: "потрібен",
                photo: "146.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98499002399021,
                    46.972981042363145
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 147,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "147.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9851776287362,
                    46.973272621762106
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 148,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "148.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985327017700598,
                    46.97348123043784
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 149,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98545208753125,
                    46.97367324452243
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 150,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985611898981535,
                    46.973839182064886
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 151,
                lamp: "LED",
                headband: 0,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98575781378397,
                    46.974116533664905
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 152,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.985917625234247,
                    46.974303804444546
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 153,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986067014198646,
                    46.97450055658255
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 154,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98623377397286,
                    46.97478501622509
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 155,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "155.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98639011126118,
                    46.975003100926315
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 156,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "156.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.9866402509225,
                    46.975353930970776
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 157,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.986817433182594,
                    46.9756051997232
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 158,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "158.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98704325371017,
                    46.975934691864026
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 159,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: "159.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987310764181295,
                    46.976268922824715
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 160,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98760259378616,
                    46.97666715273132
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 161,
                lamp: "DNAT",
                headband: 1,
                repair: "заміна-нахилений",
                photo: "161.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.987741560264666,
                    46.97685204417983
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 162,
                lamp: "DNAT",
                headband: 1,
                repair: "потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.98790832003888,
                    46.977070120451245
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 163,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.988005596573835,
                    46.977217084393374
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 164,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: "164.jpg"
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.988158459700195,
                    46.97746360364733
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 165,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.988415917277134,
                    46.97781159329471
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 166,
                lamp: "DNAT",
                headband: 1,
                repair: "не потрібен",
                photo: null
            },
            geometry: {
                type: "Point",
                coordinates: [
                    31.988597091127573,
                    46.97807893858551
                ]
            }
        }
    ]
}