import {Collapse, Image} from 'antd';

const {Panel} = Collapse;

export const Text = () => {
    return (
        <div className={'analiz'}>
            <h1>Загальна концепція для технічного завдання по проєктам ремонтів мереж вуличного освітлення</h1>
            <Collapse accordion defaultActiveKey={['1']}>
                <Panel header="Огляд поточного стану" key="1">
                    {/*Огляд поточного стану*/}
                    <p>Недостатність зовнішнього освітлення в мікрорайонах міста і на дорогах, є однією з причин
                        ускладнення
                        криміногенної ситуації та дорожньо-транспортних пригод. У сукупності з незадовільним станом
                        покриття
                        тротуарів та доріг в місті, збільшується і ризик травматизму та небезпеки пересування по вулицях
                        міста,
                        як для пішоходів, так і для водіїв автотранспортних засобів. Недостатність зовнішнього
                        освітлення
                        тротуарів та доріг, є однією з причин ускладнення криміногенної ситуації в місті.</p>
                    <p><b>На сьогоднішній день по вул. Пушкінській</b> для освітлення використовують застаріли марки
                        світильників та
                        ламп освітлення. В цих світильниках внаслідок корозії світловідбивачі втратили свої властивості,
                        відсутні захисні плафони. <b>Фактичний ККД (коефіцієнт корисної дії)</b> використання світлового
                        потоку
                        джерела світла становить <b>менш 50%.</b> Окрім того, що існуючі лампи мають високі показники
                        енергоспоживання,
                        то ще на вулицях, освітлених такими світильниками, <b>показники освітленості в 1,5-2 рази нижче
                            від
                            встановлених вимог діючих Державних будівельних норм та стандартів (ДБН В.2.5-28 та ДСТУ EN
                            13201-2).</b> Також Існуючий
                        технічний стан світильників призводить до неякісного освітлення та неефективного використання
                        електричної енергії. Через зношеність світильників і електромереж відбуваються аварійні
                        ситуації, такі
                        як коротке або міжфазне замикання ліній живлення світильників, передчасний вихід з ладу ламп і
                        тому
                        подібне. Існуючі стойки опор були встановлені в різні проміжки часу та мають різні типи,
                        знаходяться в
                        основному в задовільному стані. Частина світильників встановлена на опорах сумісного
                        використання.
                        Інвентаризація опор останній раз проводилась ще за часів радянського союзу. Частина опор
                        належить КП
                        «Миколаївелектротранс». Вуличні світильники встановлені в більшості на залізобетонних опорах з
                        різною
                        висотою від 7 до 11 м. Є окремі опори, що потребують заміни внаслідок фізичного зношення.
                    </p>
                    <p><b>Характерними дефектами залізобетонних опор та фундаментів є:</b></p>
                    <ul>
                        <li>поздовжні і поперечні тріщини стойок опор;</li>
                        <li>вибоїни, отвори в бетоні стойок;</li>
                        <li>зміщення каркаса арматури стойок опори, вихід арматури на поверхню бетону;</li>
                        <li>корозія арматури;</li>
                        <li>неякісне закладання опор в ґрунті, відсутність ригелів;</li>
                        <li>тріщини, відколи й осипання бетону фундаментів, корозія арматури фундаментів;</li>
                        <li>нещільне прилягання п'яти опори до поверхні фундаменту;</li>
                        <li>відхилення опори від вертикального положення.</li>
                    </ul>
                    <div className={'img-galary'}>
                        <Image.PreviewGroup>
                            <Image width={165} src="../lamp-post/5.jpg" />
                            <Image width={165} src="../lamp-post/7_7.jpg" />
                            <Image width={165} src="../lamp-post/18.jpg" />
                            <Image width={165} src="../lamp-post/48.jpg" />
                            <Image width={165} src="../lamp-post/64.jpg" />
                            {/*<Image width={150} src="../lamp-post/75_75.jpg" />*/}
                        </Image.PreviewGroup>
                    </div>
                </Panel>
                <Panel header="Пропозиції щодо модернізації" key="2">
                    {/*Пропозиції щодо модернізації*/}
                    <p>Якщо зосередитись конкретно на вулиці Пушкінський (від старого залізничного вокзалу до
                        Інгульского мосту), то попереднім обстеженням, встановлено – на вулиці розташовано 166
                        електроопор, з яких:</p>
                    <ul>
                        <li>111 використовуються для безпосередньо вуличного освітлення,</li>
                        <li>55 використовуються як опори для утримання дротів живлення мережі тролейбусних та трамвайних
                            маршрутів.
                        </li>
                    </ul>
                    <p>З загальної кількості вказаних опор:</p>
                    <ul>
                        <li>41 - знаходяться в незадовільному технічному стані,</li>
                        <li>9 опор взагалі потребують заміни внаслідок значного викрошування залізобетону та (або)
                            значного кута
                            нахилу опори.
                        </li>
                        <li>на 42 опорах пошкоджені або взагалі відсутні оголовники освітлювальних приладів.</li>
                        <li>Окремі ліхтарі для освітлення пішохідних тротуарів на вулиці не передбачено взагалі.</li>
                        <li>Для освітлення проїжджої частини використовуються 107 застарілих світильників з лампами ДНАТ
                            загальною потужністю близько 64 200 Вт.
                        </li>
                    </ul>
                    <p>Загалом <b>річні витрати для освітлення вулиці Пушкінської наразі становлять близько 253 911
                        кВт/год</b> (3 955
                        годин на рік Х на 600 Вт Х 107 ламп). <b>Якщо в проєкті застосувати сучасні енергоефективні
                            світлодіодні
                            світильники</b> вуличного освітлення потужністю 100 Вт, то після введення нового обладнання
                        в експлуатацію
                        річні витрати на освітлення вулиці Пушкінської становитимуть близько 42 318,5 квт/год (3 955
                        годин на
                        рік Х на 100 Вт Х 107 ламп). Тобто <b>місто отримає економію на вуличному освітленні близько 402
                            026 грн
                            на
                            рік!!!</b> (211 592,5 кВт/год економії Х на тариф 1,9 грн за 1 кВт/год).
                        Також в технічному завданні для складання проєкту по реконструкції системи освітлення по вул.
                        Пушкінська
                        пропонуємо вказати на необхідності запроєктувати на кожному перехресті світильники з більш
                        потужним
                        рівнем освітлення з усіх сторін кожного перехрестя, що дасть змогу знизити аварійність на
                        перехрестях.
                        Ці ж вимоги щодо посиленого освітлення застосуватимуться для кожної ділянки нерегульованих
                        пішохідних
                        переходів.
                        Окрім цього обстеженням встановлено, що з 32 встановлених на прехрестях по вул. Пушкінська
                        світлофорів
                        лише 8 обладнані світловою таймерною системою попередження пішоходів (водіїв) з відліком залишку
                        часу
                        для перетину вулиці. Загалом, якщо казати про підвищення рівню безпеки для водіїв та пішоходів
                        пропонується розглянути можливість застосувати максимальну кількість систем безпеки зокрема на
                        регульованих світлофорами перехрестях, та загалом на вул. Пушкінська, а саме наприклад такі
                        системи:</p>
                    <ol>
                        <li>На регульованих пішохідних переходах передбачити світлофори, що за допомогою лазера
                            транслюють
                            червоний чи зелений сигнал ще й на асфальті (або «повітряна завіса») доріг (для водіїв) та
                            тротуарів
                            (для пішоходів). <span className={'finalize'}>Посилання на приклад??????????</span>
                        </li>
                        <li>На окремих регульованих перехрестях передбачити світлофори з винесеними дистанційними
                            датчиками руху
                            автомобілів. Якщо автомобіль за 100-150 метрів до світлофора рухається з вагомим
                            перевищенням
                            швидкості, то світлофор автоматично вмикає красне світло для транспортного потоку з цього
                            напрямку.
                            <span className={'finalize'}>Посилання на приклад??????????</span>
                        </li>
                        <li>На нерегульованих пішохідних переходах передбачити ділянки з додатковим, посиленим
                            підсвічуванням
                            ділянки пішохідного переходу (про що йшлося вище). <span className={'finalize'}>Посилання на приклад??????????</span>
                        </li>
                        <li>На всіх перехрестях передбачити посилене підсвічування усіх сторін перехрестя (про що йшлося
                            вище),
                            тощо. <span className={'finalize'}>Посилання на приклад??????????</span>
                        </li>
                    </ol>
                </Panel>
                <Panel header="Можливі інноваційні рішення" key="3">
                    {/*Можливі інноваційні рішення*/}
                    <p>Окрім вищенаведеного, пропонуємо під час складання технічного завдання для проєктування
                        оголосити, окрім
                        інших, таки вимоги:</p>
                    <ol>
                        <li>В ході проєктування передбачити виключно світильники з енергоефективними
                            (енергозберігаючими)
                            лампами, класу енергоефективності не нижче «А+».
                        </li>
                        <li>Передбачити співвідношення світлового потоку до потужності на виході з світильника
                            (ефективність),
                            Лм/Вт – не менше 140.
                        </li>
                        <li>Передбачити можливість зниження рівня зовнішнього освітлення (тротуарів) вулиці в нічний час
                            (для
                            прикладу – починаючи з 23-00 до 5-00 ранку вимикаються два через один світильника освітлення
                            тротуарів, або інший варіант).
                        </li>
                        <li>Передбачити можливість зниження рівня освітлення проїжджої частини вулиці в залежності від
                            інтенсивності дорожнього руху (для прикладу – починаючи з 23-00 до 5-00 ранку вимикаються
                            один через
                            два світильника освітлення тротуарів, або інший варіант).
                        </li>
                        <li>Передбачити проєктом розміщення шаф (або шаф з прокладанням дротів телекомунікаційної
                            мережі) для
                            подальшого монтажу мереж відеоспостереження за програмою «Безпечне місто».
                        </li>
                        <li>Передбачити обов’язкову заміну аварійних електричних опор.</li>
                        <li>Дизайн опор (кронштейнів) світильників повинен вписуватись в архітектурний ландшафт міста.
                        </li>
                    </ol>
                </Panel>
            </Collapse>
        </div>
    );
}