import React from "react";

import L from "leaflet";
import {MapContainer, TileLayer} from "react-leaflet";

// import {MyMarker} from "../components/MyMarker";
// import {MyPolyline} from "../components/MyPolyline";
// import {MyPolygon} from "../components/MyPolygon";
// import {CarSingle} from "../components/Car/CarSingle";

import {markers} from "../data/markers";
// import {polylineList} from "../data/polylines";
// import {polygonsList} from "../data/polygons";

import {LayerMarkers} from "../layers/LayerMarkers";
// import {LayerPolyline} from "../layers/LayerPolylines";
// import {LayerPolygons} from "../layers/LayerPolygons";

export const Map = () => {
    // const position: L.LatLngExpression = [46.95355352880178, 32.03006707746631] default map;
    const position: L.LatLngExpression = [46.973599,31.984971];

    return (
        <MapContainer center={position}
                      zoom={16}
                      minZoom={12}
                      maxZoom={18}
                      scrollWheelZoom={true}
                      zoomControl={true}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LayerMarkers data={markers}/>

            {/*<LayerPolyline data={polylineList}/>*/}
            {/*<LayerPolygons data={polygonsList}/>*/}
            {/*<MyMarker/>*/}
            {/*<MyPolyline/>*/}
            {/*<MyPolygon/>*/}
        </MapContainer>
    );
};
