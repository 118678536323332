import React from 'react';

import "leaflet/dist/leaflet.css";
import './Style/App.scss';

import {Map} from "./Map/Map";

import { Col, Row, BackTop} from 'antd';

import {Text} from "./components/text/Text";

function App() {
  return (
      <>
          <Row>
              <Col md={12} sm={24} className={'text'}><Text/></Col>
              <Col md={12} sm={24} className={'map'}><Map/></Col>
          </Row>
          <BackTop className={'backTop'}/>
      </>


    // <Row >
    //     <Col flex={3} className={'text'}>TEXT</Col>
    //     <Col flex={3} className={'map'}>MAP</Col>
    //
    // </Row>
  );
}

export default App;