import L from "leaflet";

import streetLamp_green_SVG from "../images/svg/icon-street_lamp_green.svg";
import streetLamp_red_SVG from "../images/svg/icon-street_lamp_red.svg";
import streetLamp_red_photo_SVG from "../images/svg/icon-street_lamp_red_photo.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [30, 37],
        iconAnchor: [15, 37],
        tooltipAnchor: [15, -22]
    },
});
// @ts-ignore
export const streetLampIconR = new LeafIcon({iconUrl: streetLamp_red_SVG});
// @ts-ignore
export const streetLampIconR_P = new LeafIcon({iconUrl: streetLamp_red_photo_SVG});
// @ts-ignore
export const streetLampIconG = new LeafIcon({iconUrl: streetLamp_green_SVG});
