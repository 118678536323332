import React, { useState } from 'react';

import {Marker, Popup, Tooltip, useMap} from "react-leaflet";
import {streetLampIconR, streetLampIconR_P, streetLampIconG} from "../icons/streetLampIcon";
import {Card, Image} from "antd";
import {featureGroup} from "leaflet";

// @ts-ignore
const PopupStatistics = ({feature}) => {
    const {
        fid,
        lamp,
        headband,
        repair,
        photo,
    } = feature.properties;

    const [visible, setVisible] = useState(false);

    // let image = false;

    if (photo) {
        return (
            <Card type="inner"
                  className={"card-street-lamp"}
                  title={`№ ${fid}`}
                  // extra={<a
                  //     href="https://docs.google.com/spreadsheets/d/1ePABTG8nsKgri7NDPXXFDGJ3G1AyWGQWHbBo8kRg564/edit?usp=sharing"
                  //     rel="noreferrer"
                  //     target={"_blank"}>Усі будівлі</a>}
            >
                <div className={"line"}>
                    <b>Лампа:</b>
                    <span>{lamp}</span>
                </div>
                <div className={"line"}>
                    <b>Ремонт:</b>
                    <span>{repair}</span>
                </div>
                <div className={"line"}>
                    <b>Оголовник:</b>
                    <span>{headband}</span>
                </div>
                <Image
                    preview={{ visible: false }}
                    width={200}
                    src={`../lamp-post/small/${photo}`}
                    onClick={() => setVisible(true)}
                />
                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                        <Image src={`../lamp-post/${photo}`} width={400} />
                    </Image.PreviewGroup>
                </div>
            </Card>
        )
    } else {
        return (
            <Card type="inner"
                  className={"card-street-lamp"}
                  title={`№ ${fid}`}
                  // extra={<a
                  //     href="https://docs.google.com/spreadsheets/d/1ePABTG8nsKgri7NDPXXFDGJ3G1AyWGQWHbBo8kRg564/edit?usp=sharing"
                  //     rel="noreferrer"
                  //     target={"_blank"}>Усі будівлі</a>}
            >
                <div className={"line"}>
                    <b>Лампа:</b>
                    <span>{lamp}</span>
                </div>
                <div className={"line"}>
                    <b>Ремонт:</b>
                    <span>{repair}</span>
                </div>
                <div className={"line"}>
                    <b>Оголовник:</b>
                    <span>{headband}</span>
                </div>
            </Card>
        )
    }

}

// @ts-ignore
export const LayerMarkers = ({data}) => {
    const leafletMap = useMap();
    let streetLamp = streetLampIconG;

    return (
        // @ts-ignore
        data.features.map((feature, index )=>{
            const {coordinates} = feature.geometry;
            const {photo, lamp} = feature.properties;

            if (lamp === "DNAT") {streetLamp = streetLampIconR}
            else if (lamp === "DRL") {streetLamp = streetLampIconR}
            else if (lamp === "LED") {streetLamp = streetLampIconG}

            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={streetLamp}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Popup className={'knm-popup'}>
                        <PopupStatistics feature={feature}/>
                    </Popup>
                    <Tooltip>
                        {lamp}
                    </Tooltip>
                </Marker>
            )
        })
    )
}